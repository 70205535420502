// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-home-js": () => import("./../../../src/pages/en/home.js" /* webpackChunkName: "component---src-pages-en-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-medarbejdere-js": () => import("./../../../src/pages/medarbejdere.js" /* webpackChunkName: "component---src-pages-medarbejdere-js" */),
  "component---src-templates-gutenberg-wp-gutenberg-js": () => import("./../../../src/templates/gutenberg/WpGutenberg.js" /* webpackChunkName: "component---src-templates-gutenberg-wp-gutenberg-js" */),
  "component---src-templates-job-wp-job-js": () => import("./../../../src/templates/job/WpJob.js" /* webpackChunkName: "component---src-templates-job-wp-job-js" */),
  "component---src-templates-seo-wp-seo-js": () => import("./../../../src/templates/seo/WpSEO.js" /* webpackChunkName: "component---src-templates-seo-wp-seo-js" */),
  "component---src-templates-ydelse-wp-ydelse-en-js": () => import("./../../../src/templates/ydelse/WpYdelseEN.js" /* webpackChunkName: "component---src-templates-ydelse-wp-ydelse-en-js" */),
  "component---src-templates-ydelse-wp-ydelse-js": () => import("./../../../src/templates/ydelse/WpYdelse.js" /* webpackChunkName: "component---src-templates-ydelse-wp-ydelse-js" */)
}

